/* eslint-disable */
import React from 'react';
import Img from "gatsby-image";
import sendEvent from '../helpers/sendAnalytics';

class TwoColumnContent extends React.Component {
  render() {
    return (
      <div className="paragraph--type--two-column-centered-text">
        <div className="field__items">
          {this.props.data.relationships.field_two_column_content.map(column => {
            return (
              <div className="field__item" key={column.id}>
                <div className="centered-text-with-button">
                  <div className="centered-text-with-button-text section-body-text">
                    <div className="clearfix text-formatted field field--name-field-body-text field--type-text-long field--label-hidden field__item" dangerouslySetInnerHTML={{__html: column.field_body_text.value }}></div>
                  </div>
                  <a target="_blank" onClick={() => sendEvent({
                    ga: {
                      category: 'Careers_External',
                      action: 'Click',
                      label: column.field_link.title == 'Careers' ? 'Careers_External_Link' : 'About_Us_YouTube'
                    }
                  })} href={column.field_link.uri} className="centered-text-with-button-button button blue-outline">{column.field_link.title}</a>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

export default TwoColumnContent
