/* eslint-disable */
import React from 'react';
import Img from "gatsby-image";

class CenteredTextContent extends React.Component {
  render() {
    return (
      <div className="centered-text">
        <div className="section-title centered">{this.props.data.field_title}</div>
        <div className="large-centered-text section-body-text" dangerouslySetInnerHTML={{__html: this.props.data.field_body_text.value }}></div>
      </div>
    )
  }
}

export default CenteredTextContent
