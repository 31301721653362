/* eslint-disable */
import React from 'react';
import Img from "gatsby-image";

class HeaderBanner extends React.Component {
  render() {
    return (
      <div className="header-banner" >
        { (this.props.data.relationships.field_header_banner_image.field_image && this.props.data.relationships.field_header_banner_image.field_image.localFile) ?
          <Img sizes={this.props.data.relationships.field_header_banner_image.field_image.localFile.childImageSharp.sizes} fadeIn={false} />
        : null }
      </div>
    )
  }
}

export default HeaderBanner
