/* eslint-disable */
import React from 'react';
import Img from "gatsby-image";

class TextLeftImageRightBackground extends React.Component {
  render() {
    return (
      <div className="image-left-text-right-with-background reverse">
        <div className="image-left-text-right-with-background-image">
          { (this.props.data.relationships.field_image && this.props.data.relationships.field_image.localFile) ?
            <Img sizes={this.props.data.relationships.field_image.localFile.childImageSharp.sizes} fadeIn={false} />
          : null }
        </div>

          <div className="image-left-text-right-with-background-text">
          <div className="image-left-text-right-with-background-text-title intro-bold blue-text">{this.props.data.field_title}</div>
          <div className="image-left-text-right-with-background-text-body body-text" dangerouslySetInnerHTML={{__html: this.props.data.field_body_text.value }}></div>
          <div className="image-left-text-right-with-background-text-list">
            <div className="image-left-text-right-with-background-text-list-item">
              <div className="text-list-item-number bold-label">{ this.props.data.field_conservation_sites_value }</div>
              <div className="text-list-item-text section-body-text">{ this.props.data.field_conservation_sites_label }</div>
            </div>
            <div className="image-left-text-right-with-background-text-list-item">
              <div className="text-list-item-number bold-label">{ this.props.data.field_forest_research_value }</div>
              <div className="text-list-item-text section-body-text">{ this.props.data.field_forest_research_label }</div>
            </div>
            <div className="image-left-text-right-with-background-text-list-item">
              <div className="text-list-item-number bold-label">{ this.props.data.field_forest_cycle_value }</div>
              <div className="text-list-item-text section-body-text">{ this.props.data.field_forest_cycle_label }</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default TextLeftImageRightBackground
