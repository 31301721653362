/* eslint-disable */
import React from 'react'
import Img from "gatsby-image";
import { Link } from "gatsby";

class ImageLeftTextRightHeader extends React.Component {
  render() {
    return (
      <div className="image-left-text-right-header">
        <div className="image-left-text-right-header-image" style={{backgroundImage: `url(${this.props.data.relationships.field_image.localFile.childImageSharp.resolutions.src})`}}></div>
        <div className="image-left-text-right-header-text">
          <div className="section-title sm-margin" dangerouslySetInnerHTML={{__html: this.props.data.field_heading_title.value }}></div>
          <div className="section-body-text sm-margin" dangerouslySetInnerHTML={{__html: this.props.data.field_body_text.value }}></div>

          <div className="icon-list">
            <div className="icon-list-wrapper">
              <div className="icon-list-item">
                <div className="icon-list-image"></div>
                <div className="icon-list-text intro-light">{this.props.data.field_list_items[0]}</div>
              </div>
              <div className="icon-list-item">
                <div className="icon-list-image"></div>
                <div className="icon-list-text intro-light">{this.props.data.field_list_items[1]}</div>
              </div>
              <div className="icon-list-item">
                <div className="icon-list-image"></div>
                <div className="icon-list-text intro-light">{this.props.data.field_list_items[2]}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ImageLeftTextRightHeader
