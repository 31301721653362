/* eslint-disable */
import React, { Component } from 'react';
import ReactModal from 'react-modal';

import close from "./../layouts/images/white-close.svg";
import prev from "./../layouts/images/prev.svg";
import next from "./../layouts/images/next.svg";
import { Swipeable } from 'react-swipeable'

class GalleryModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentImage: this.props.initialImage,
      images: []
    };

    this.handleNext = this.handleNext.bind(this);
    this.handlePrev = this.handlePrev.bind(this);
  }

  handleNext() {
    if((this.state.currentImage + 1) >= this.props.images.length) {
      this.setState({
        currentImage: 0
      })
    } else {
      this.setState({
        currentImage: this.state.currentImage + 1
      })
    }
  }

  handlePrev() {
    if(this.state.currentImage === 0) {
      this.setState({
        currentImage: this.props.images.length - 1
      });
    } else {
      this.setState({
        currentImage: this.state.currentImage - 1
      })
    }
  }

  componentDidMount() {
    ReactModal.setAppElement('body');
  }

  render() {
    return (
      <ReactModal
        isOpen={this.props.showModal}
        onRequestClose={() => this.setState({
          currentImage: null
        }, () => {
          this.props.closeModal()
        })}
        className="blog-modal-wrapper"
        overlayClassName="blog-modal"
      >
        <a className="blog-modal-close"><img src={close} onClick={() => this.setState({
          currentImage: null
        }, () => {
          this.props.closeModal()
        })} /></a>

        <Swipeable onSwipedLeft={this.handleNext} onSwipedRight={this.handlePrev}>
          <div className="blog-modal-content">
            {this.props.images && this.props.images[this.state.currentImage] ? <img src={this.props.images[this.state.currentImage].url} /> : null}
            {this.props.images && this.props.images[this.state.currentImage] && this.props.images[this.state.currentImage].caption ? <div className="blog-modal-caption">{this.props.images[this.state.currentImage].caption}</div> : null}
          </div>
        </Swipeable>

        <div className="prev-next">
          {this.props.images.length > 1?
            <div className="prev-next-wrapper">
              <div className="prev" onClick={this.handlePrev}><img src={prev} /></div>
              <div className="next" onClick={this.handleNext}><img src={next} /></div>
            </div>
          :
            null
          }
        </div>
      </ReactModal>
    )
  }
}

export default GalleryModal
