/* eslint-disable */
import React from 'react'
import Img from "gatsby-image";
import { Link } from "gatsby";
import sendEvent from '../helpers/sendAnalytics';

class ImageLeftTextRight extends React.Component {
  render() {
    return (
      <div className="image-left-and-text-right frontpage">
        <div className="image-left-text-right-image">
          { (this.props.data.relationships.field_image && this.props.data.relationships.field_image.localFile) ?
            <img src={this.props.data.relationships.field_image.localFile.childImageSharp.sizes.src} />
          : null }
        </div>

        <div className="image-left-text-right-text home-image-left-text-right-text">
          <div className="image-left-text-right-text-wrapper">
            <h2 className="section-title">{this.props.data.field_title}<span className="sub-title">{this.props.data.field_sub_title}</span></h2>
            <p className="section-body-text" dangerouslySetInnerHTML={{__html: this.props.data.field_body_text.value }}></p>
            <div className="arrow-link">
              <svg width="6px" height="10px" viewBox="0 0 6 10" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                  <g id="01-Home" transform="translate(-835.000000, -1171.000000)" stroke="#2B87BB" strokeWidth="2">
                    <g id="Group-5" transform="translate(836.000000, 1166.000000)">
                      <polyline id="Shape-Copy-2" points="0 6 4 10 0 14"></polyline>
                    </g>
                  </g>
                </g>
              </svg>
              <Link to="/products" onClick={() => sendEvent({
                ga: {
                  category: 'Engagement',
                  action: 'Click',
                  label: 'Homepage_Explore_Products'
                }
              })} className="blue-arrow-link">Explore Products</Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ImageLeftTextRight
