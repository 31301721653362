/* eslint-disable */
import React from 'react';
import Img from "gatsby-image";
import { Link } from "gatsby";

import sendEvent from '../helpers/sendAnalytics';

class TextLeftImageRight extends React.Component {
  render() {
    return (
      <div className="text-left-and-image-right">
        <div className="text-left-and-image-right-text">

          <h2 className="section-title text-right-title home-section-title">
            {this.props.data.field_title}
            <span className="sub-title text-right-sub-title">{this.props.data.field_sub_title}</span>
          </h2>

          {this.props.blogs.edges.map(blog => {
            return (
              <Link
                to={blog.node.path.alias}
                key={blog.node.id}
                onClick={() => sendEvent({
                  ga: {
                    category: 'Engagement',
                    action: 'Click',
                    label: 'Homepage_BCH_CTA'
                  }
                })}
              >
                <div className="text-link-with-blue-arrow">
                  <div className="text-link-text">
                    <div className="text-link-info">
                      <div className="text-link-title">{blog.node.title}</div>
                      <div className="text-link-details">
                        { /* <div className="text-link-date">{blog.node.created}</div> */ }
                        <div className="text-link-author">by <span className="text-link-author-name">{blog.node.field_author}</span></div>
                      </div>
                    </div>
                  </div>
                  <div className="text-link-arrow">
                    <svg width="10px" height="17px" viewBox="0 0 10 17" version="1.1" xmlns="http://www.w3.org/2000/svg">
                      <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                        <g id="01-Home" transform="translate(-765.000000, -1518.000000)" stroke="#2B87BB" strokeWidth="2">
                          <g id="Group-4-Copy-4" transform="translate(130.000000, 1473.000000)">
                            <polyline id="Shape-Copy-2" points="636 46 643.5 53.5 636 61"></polyline>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </div>
                </div>
              </Link>
            )
          })}

          <div className="arrow-link">
            <svg width="6px" height="10px" viewBox="0 0 6 10" version="1.1" xmlns="http://www.w3.org/2000/svg">
              <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                <g id="01-Home" transform="translate(-835.000000, -1171.000000)" stroke="#2B87BB" strokeWidth="2">
                  <g id="Group-5" transform="translate(836.000000, 1166.000000)">
                    <polyline id="Shape-Copy-2" points="0 6 4 10 0 14"></polyline>
                  </g>
                </g>
              </g>
            </svg>
            <Link
              to="/bringcomforthome"
              className="blue-arrow-link"
              onClick={() => sendEvent({
                ga: {
                  category: 'Engagement',
                  action: 'Click',
                  label: 'Homepage_BCH_CTA'
                }
              })}
            >
              See All
            </Link>
          </div>

        </div>

        <div className="text-left-and-image-right-image">
          { (this.props.data.relationships.field_image && this.props.data.relationships.field_image.localFile) ?
            <img src={this.props.data.relationships.field_image.localFile.childImageSharp.sizes.src} />
          : null }
        </div>
      </div>
    )
  }
}

export default TextLeftImageRight
