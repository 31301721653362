/* eslint-disable */
import React from 'react'
import Link from 'gatsby-link'

import SEO from "../components/seo.js";
import Header from "../components/header.js";
import GeneralFooter from '../components/generalFooter';

import Layout from '../components/layout'
import { graphql } from 'gatsby'


// Import paragraphs
import CenteredTextContent from '../paragraphs/centered_text_content.js';
import ImageLeftTextRightHeader from '../paragraphs/image_left_text_right_header.js';
import ImageLeftTextRight from '../paragraphs/image_left_text_right.js';
import TextLeftImageRight from '../paragraphs/text_left_image_right.js';
import CenteredTitleWithSubheader from '../paragraphs/centered_title_with_subheader.js';
import FullWidthImageImg from '../paragraphs/full_width_image_img.js';
import HeaderBanner from '../paragraphs/header_banner.js';
import ImageLeftTextRightBackground from '../paragraphs/image_left_text_right_background.js';
import TwoColumnContent from '../paragraphs/two_column_content.js';
import TextLeftImageRightBackground from '../paragraphs/text_left_image_right_background.js';

import ImgLeft1 from '../assets/images/imgLeft1@2x.jpg'
import ImgRight1 from '../assets/images/imgRight1@2x.jpg'

import partnerLogo1 from '../assets/images/partner1.svg'
import partnerLogo2 from '../assets/images/partner2.svg'
import partnerLogo3 from '../assets/images/cavendish_farms_logo.png'

class AboutPage extends React.Component {
  render() {
    const data = this.props.data.nodePage
    const retailers = this.props.data.allNodeStore.edges

    /* Germ Protection campaign adjustment, remove after campaign */
    if(data.relationships.field_paragraph.length == 6){
      data.relationships.field_paragraph.splice(0,2)
    }

    return (
      <Layout>
        <div className="page-wrapper bringComfortHome">
          <SEO title="Scotties Helps | Scotties Facial" meta={data.field_meta_tags} />
          <Header retailers={retailers}></Header>
          <div style={{clear:'both'}} />
          {/* Germ Protection campaign adjustment, remove after campaign */}
          <div class="centered-title-and-text blog-centered-title-and-text">
            <div class="section-title centered font-92">
              Scotties Helps
            </div>
            <div class="section-body-text centered larger-container">
              <p>We’re proud as Americans that so many are doing their part to slow the spread of COVID-19. We know it takes a community effort to help keep each other safe. We want to thank our employees, our retail partners and their front-line staff, and everyone who is doing their part to lower the risks for themselves and their neighbors.</p>
            </div>
          </div>

          <div className="germ-protect">
            <div className="image-left-text-right">
              <div>
                <img src={ImgLeft1} />
              </div>
              <div className="pad-cell">
                <h5>What We’re Doing</h5>
                <quote>
                  “It’s important that as a community, we take care of our neighbors who are impacted.”
                  <span> - Robert K. Irving, Co-CE0 of J.D. Irving, Limited</span>
                </quote>
                <p className="bigger">In this time of difficulty, we wanted to help the Americans who need it most. To do that we’ll be donating relief efforts in Massachusetts & New York State.</p>
                <Link to="/bringcomforthome#community" className="button outline-button">Full Details</Link>
              </div>
            </div>
            <div className="blue-section">
              <div className="image-left-text-right">
                <div>
                  <h5>Our COVID-19 Safety Measures</h5>
                  <p>The Coronavirus (COVID-19) outbreak is creating a rapidly changing environment and Governments have recognized tissue production as an essential service during these challenging times. We are doing everything possible to ensure we keep producing the Scotties<sup>®</sup> tissue products that Americans need to keep their homes clean and healthy. The safety and well-being of our employees and communities remains our first priority. </p>
                  <p>It’s for these reasons that we have put measures in place to do our best to protect one another – including work-from-home arrangements for some of our employees, and limiting the number of people at any given worksite to those team members required for the safe and reliable operation of our business. </p>
                  <p>As well, employees across our business are respecting health and safety measures as outlined by our local public health agencies to prevent the spread of the COVID-19:</p>
                </div>
                <div>
                  <ul>
                      <li>There are new protocols regarding screening at site entrances.  </li>
                      <li>Virtual meetings are encouraged for employee teams and customers. </li>
                      <li>We have stopped all international travel and all domestic air travel to limit potential exposure. </li>
                      <li>We have significantly enhanced sanitization and disinfection protocols at our facilities. </li>
                      <li>We have provided up to 3 paid emergency leave days for employees. This leave is available to help with family commitments such as managing alternate childcare arrangements during school closures or to care for sick or vulnerable family members during the Coronavirus (COVID-19) outbreak. </li>
                      <li>We’ve introduced pay continuation for self-isolation due to Coronavirus (COVID-19). </li>
                      <li>We have a senior leadership team dedicated to this effort 24/7 and are providing daily updates to our employees. </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="image-left-text-right">
              <div className="pad-cell2">
                <h5>Bring Comfort Home</h5>
                <p>In this unprecedented time, it is more important than ever to have good information on maintaining a safe and healthy home. To help, we’ve collected information on how to implement the official health recommendations. </p>
                <Link to="../bringcomforthome/navigating-coronavirus-covid-19" className="button outline-button">Full Details</Link>
              </div>
              <div>
                <img src={ImgRight1} />
              </div>
            </div>
            <div className="image-left-text-right">
              <div className="partners">
                <a href="https://jdirving.com/HomePage.aspx?LangType=1033" rel="noopener noreferrer" target="_blank"><img src={partnerLogo1} alt="Logo" /></a>
                <a href="https://www.midlandtransport.com/" rel="noopener noreferrer" target="_blank"><img src={partnerLogo2} alt="Logo" /></a>
                <a href="https://cavendishfarms.com/en" rel="noopener noreferrer" target="_blank"><img src={partnerLogo3} alt="Logo" /></a>
              </div>
              <div className="pad-cell2">
                <h5>Partners and affiliates</h5>
                <p>Our parent company, partners and affiliates are all implementing new measures to increase safety and help take care of our employees, neighbors and communities at this time.  </p>
              </div>
            </div>
          </div>

          {/* END Germ Protection campaign adjustment, remove after campaign */}

          <div className="germ-adjust">
            {data.relationships.field_paragraph.map(paragraph => {
              switch(paragraph.__typename) {
                case 'paragraph__image_left_text_right_header':
                  return (
                    <ImageLeftTextRightHeader key={paragraph.id} data={paragraph} />
                  );
                  break;
                case 'paragraph__header_banner':
                  return (
                    <HeaderBanner key={paragraph.id} data={paragraph} />
                  );
                  break;
                case 'paragraph__centered_title_with_subheader':
                  return (
                    <CenteredTitleWithSubheader key={paragraph.id} data={paragraph} />
                  );
                  break;
                case 'paragraph__full_width_image':
                  return (
                    <FullWidthImageImg key={paragraph.id} data={paragraph} />
                  );
                  break;
                case 'paragraph__image_left_text_right_background':
                  return (
                    <ImageLeftTextRightBackground key={paragraph.id} data={paragraph} blogs={this.props.data.allNodeBlog} />
                  );
                  break;
                case 'paragraph__text_left_image_right_background':
                  return (
                    <TextLeftImageRightBackground key={paragraph.id} data={paragraph} blogs={this.props.data.allNodeBlog} />
                  );
                  break;
                case 'paragraph__two_column_centered_text':
                  return (
                    <TwoColumnContent key={paragraph.id} data={paragraph} blogs={this.props.data.allNodeBlog} />
                  );
                  break;
              }
            })}
          </div>


          <div className="row-text-with-two-columns">
            <div className="row-text-with-two-columns-title intro-bold">Frequently Asked&nbsp; Questions</div>
            <div className="row-text-with-two-columns-wrapper">
              {data.relationships.field_frequently_asked_questions.map(question => {
                return (
                  <div className="row-text-with-two-columns-row">
                    <div className="row-text-with-two-columns-row-column">
                      <div className="row-column-left-text intro-bold" dangerouslySetInnerHTML={{__html: question.field_faq_title.value}}></div>
                    </div>
                    <div className="row-text-with-two-columns-row-column">
                      <div className="row-column-right-text section-body-text" dangerouslySetInnerHTML={{__html: question.field_answer.value}}></div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
          <GeneralFooter />
        </div>
      </Layout>
    )
  }
}

export default AboutPage

export const query = graphql`
query About {
  nodePage(uuid: {eq:"4279aa27-a6c3-47a1-bdec-b8c2a2e8e4d4"}) {
    id
    title
    field_meta_tags
    relationships {
      field_frequently_asked_questions {
        ... on paragraph__frequently_asked_questions {
          field_faq_title {
            value
          }
          field_answer {
            value
          }
        }
      }
      field_paragraph {
        __typename
        ... on paragraph__image_left_text_right_header {
          id
          field_heading_title {
            value
          }
          field_sub_title
          field_body_text {
            value
          }
          field_list_items
          relationships {
            field_image {
              localFile {
                childImageSharp {
                  resolutions(width:730, quality: 85) {
                    src
                  }
                }
              }
            }
          }
        }
        ... on paragraph__header_banner {
          id
          relationships {
            field_header_banner_image {
              localFile {
                childImageSharp {
                  sizes(maxWidth: 2000, quality: 80) {
                    ...GatsbyImageSharpSizes_noBase64
                  }
                }
              }
            }
          }
        }
        ... on paragraph__centered_title_with_subheader {
          id
          field_centered_title
          field_centered_subheading {
            value
          }
        }
        ... on paragraph__full_width_image {
          id
          relationships {
            field_full_width_image {
              localFile {
                childImageSharp {
                  sizes(maxWidth: 1600, quality: 80) {
                    src
                  }
                }
              }
            }
          }
        }
        ... on paragraph__text_left_image_right_background {
          field_title
          field_body_text {
            value
          }
          field_forest_cycle_label
          field_forest_cycle_value
          field_forest_research_label
          field_forest_research_value
          field_conservation_sites_label
          field_conservation_sites_value
          relationships {
            field_image {
              localFile {
                childImageSharp {
                  sizes(maxWidth: 800, quality: 80) {
                    ...GatsbyImageSharpSizes_noBase64
                  }
                }
              }
            }
          }
        }
        ... on paragraph__image_left_text_right_background {
          id
          field_title
          field_body_text {
            value
          }
          relationships {
            field_image {
              localFile {
                childImageSharp {
                  sizes(maxWidth: 800, quality: 80) {
                    ...GatsbyImageSharpSizes_noBase64
                  }
                }
              }
            }
            field_bullet_points {
              id
              field_bullet_points_left {
                value
              }
              field_bullet_points_right
            }
          }
        }
        ... on paragraph__two_column_centered_text {
          relationships {
            field_two_column_content {
              id
              field_link {
                uri
                title
              }
              field_body_text {
                value
              }
            }
          }
        }
      }
    }
  }
  allNodeStore {
    edges {
      node {
        id
        title
        relationships {
          field_image {
            localFile {
              childImageSharp {
                resolutions(width: 143) {
                  src
                }
              }
            }
          }
          field_state {
            name
          }
        }
      }
    }
  }
}
`
