/* eslint-disable */
import React from 'react';

class CenteredTitleWithSubheader extends React.Component {
  render() {
    return (
      <div className="centered-title-and-text blog-centered-title-and-text">
        <div className="section-title centered font-92">{this.props.data.field_centered_title}</div>
        <div className="section-body-text centered" dangerouslySetInnerHTML={{__html: this.props.data.field_centered_subheading.value }}></div>
      </div>
    )
  }
}

export default CenteredTitleWithSubheader
